export const environment = {
  production: false,
  errorMessage: "Unfortunately, this service will be unavailable between 7pm - 10pm Tuesday 18th July while we carry out essential maintenance. Please revisit this link on or after 10pm on 18th July in order to submit the details we require, there is no need to contact us separately. Thank you very much for your patience and we look forward to seeing you soon.",
  isOffline: false,
  UserValidationLookupUrl:"https://hfdigitaluat.azure-api.net/heart-liability/UserIdentification",
  GetQuestionsLookupUrl:"https://hfdigitaluat.azure-api.net/heart-liability/GetQuestions",
  SubmitQuestionFormLookupUrl:"https://hfdigitaluat.azure-api.net/heart-liability/SubmitTriage",
  maxFileSizeBytes:"10485760",
  maxFileCount:"5",
  allowedFileExtensions:".pdf,.jpeg,.jpg,.png",
  sessionLengthSec:"3600",
  captchaTimeoutMsec:"10000",
  captchaKey:"6LfaipgjAAAAAHUNqOV58GyV76UzoyjtrFsY1-SK",
  ApiSubscriptionKey:"9cd6af38e20f46219f5bfdffdd7c1dc1",
  ValidatedEnvironment: 2,
  TextBoxCharactersLimit: 250,
  BigTextBoxCharactersLimit: 500
};
